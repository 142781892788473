window.addEventListener('load', ()=> {
    if (block) {
        nav();
    }
});

const block = document.querySelector('.popular-courses');

function nav() {
    const filters = block.querySelectorAll('.filter');

    filters.forEach(filter => {
        filter.addEventListener('click', ()=> {
            filters.forEach(element => {
                element.style.color = 'var(--neutral-700)';
                element.style.background = 'var(--neutral-100)';                    
            });

            filter.style.color = 'var(--neutral-100)';
            filter.style.background = 'var(--neutral-800)';

            changeActive( filter.dataset.id );
        });
    });
}

function changeActive( category ) {
    const items = block.querySelectorAll('.popular-courses-slide');
    const classes = (category == 'all') ? 'popular-courses-slide' : category;

    items.forEach(item => {
        if ( item.classList.contains(classes) ) {
            item.style.display = 'inline-block';            
        }
        else {
            item.style.display = 'none';
        }
    });
}
